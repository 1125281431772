import React, {Suspense, lazy} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
//import HomePage from "./pages/HomePage";
//import NewTeam from "./pages/NewTeam";
//import QuizPage from "./pages/QuizPage";
const HomePage = lazy(()=> import("./pages/HomePage"));
const NewTeam = lazy(()=> import("./pages/NewTeam"));
const QuizPage = lazy(()=> import("./pages/QuizPage"));

function App() {
	if ("CSS" in window && CSS.supports("display", "flex")) {
		// FlexBox is supported...
		return (
			<div className="App">
				<Router>
					<Suspense fallback={<div>Loading...</div>}>
					<Switch>
						{/* Base */}
						<Route exact path="/" component={HomePage}></Route>

						{/* Team or NewTeam */}
						<Route
							exact
							path="/quiz/:eventid/newplayer"
							component={NewTeam}
						></Route>
						<Route
							exact
							path="/quiz/:eventid/newteam"
							component={NewTeam}
						></Route>
						<Route path="/quiz/:eventid/:teamid" component={QuizPage}></Route>

						{/* Event code */}
						<Route exact path="/quiz/:eventid" component={HomePage}></Route>

						{/* Invalid event / team */}
						<Route
							exact
							path="/invalidquiz/:eventid"
							component={(props) => <HomePage {...props} warning="event" />}
						></Route>
						<Route
							exact
							path="/invalidteam/:eventid/:teamid"
							component={(props) => <HomePage {...props} warning="team" />}
						></Route>

						<Route path="*" component={HomePage}></Route>
					</Switch>
					</Suspense>
				</Router>
			</div>
		);
	} else {
		return (
			<p style={{ textAlign: "center" }}>
				This site requires features that your current browser does not support,
				please use another browser.
			</p>
		);
	}
}

export default App;
